export const structure = {
  tree: [],
  map: {},
  layerStyles: {},
  layerNames: {},
  fullDataSet: {},
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  mainGisFields: {},
  layerTypes: {},
  additionalLayers: {
    heatmap: false
  },
  additional_filters: {},
  fields: {},
  selectedModelId: null,
  activeLayers: [],
  isSelectDisabled: false,
  zIndex: {},
  scenarioStatuses: null,
  objectsCount: {},
  legendWidgetsOnMap: {}
  // currentModelId: null,
}

export const nonClearableFields = [
  'map',
  'layerStyles',
  'zIndex',
  'fullDataSet',
  'legendWidgetsOnMap'
]
