export default (beforeEnter, wrapper) => {
  return {
    name: 'planning',
    path: 'planning',
    beforeEnter,
    component: wrapper,
    meta: {
      title: 'planning:title',
      icon: 'map',
      description: 'planning:desc'
    },
    children: [
      {
        name: 'gis',
        path: 'gis',
        beforeEnter,
        component: () => import('@/modules/gis/gis'),
        meta: {
          title: 'gis:module-title',
          abbr: 'gis:abbr-module-title',
          icon: 'geometry-instruments',
          description: 'gis:module-desc',
          endpoints: ['gis'],
          projects: [
            'gbu_bdd_kazan',
            'rostov_on_don',
            'vladikavkaz',
            'cheboksary',
            'loadtest',
            'rut_miit',
            'tashkent',
            'academic',
            'labgrad',
            'vladimir',
            'brusnika',
            'cpt_spb',
            'minimal',
            'ryazan',
            'surgut',
            'eputs',
            'tomsk',
            'niipi',
            'tagil',
            'omsk',
            'madi',
            'mmk'
          ],
          isMap: true
        }
      },
      {
        name: 'ksodd-podd',
        path: 'ksodd',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'ksodd:title',
          abbr: 'ksodd:abbr-title',
          icon: 'globe',
          description: 'ksodd:desc',
          endpoints: ['ksodd'],
          projects: ['eputs', 'surgut', 'academic']
        },
        children: [
          {
            name: 'ksodd',
            path: 'ksodd',
            beforeEnter,
            component: () => import('@/modules/ksodd/ksodd'),
            meta: {
              title: 'ksodd:title',
              abbr: 'ksodd:abbr-title',
              icon: 'globe',
              description: 'ksodd:desc',
              isMap: true
            }
          },
          {
            name: 'podd',
            path: 'podd',
            beforeEnter,
            component: () => import('@/modules/ksodd/podd/podd'),
            meta: {
              title: 'ksodd:title',
              subtitle: 'ksood:podd-subtitle',
              abbr: 'ksodd:abbr-title',
              icon: 'globe',
              description: 'ksodd:desc',
              isMap: true
            }
          }
        ]
      },
      {
        name: 'tsodd',
        path: 'tsodd',
        beforeEnter,
        component: wrapper,
        redirect: 'tsodd/selector',
        meta: {
          // dev_only: true,
          // admin_only: true,
          title: 'tsodd:title',
          abbr: 'tsodd:abbr-title',
          icon: 'road-sign',
          description: 'tsodd:desc',
          endpoints: ['tsodd'],
          projects: ['eputs']
        },
        children: [
          {
            name: 'tsodd-project-selector',
            path: 'selector',
            beforeEnter,
            component: () =>
              import('@/modules/tsodd/views/tsodd-project-selector'),
            meta: {
              title: 'tsodd:title',
              icon: 'road-sign',
              abbr: 'tsodd:abbr-title',
              description: 'tsodd:desc'
            }
          },
          {
            name: 'tsodd-project',
            path: 'project-view',
            beforeEnter,
            component: () => import('@/modules/tsodd/views/tsodd-project'),
            meta: {
              title: 'tsodd:title',
              icon: 'road-sign',
              abbr: 'tsodd:abbr-title',
              description: 'tsodd:desc'
            }
          },
          {
            name: 'tsodd-registry',
            path: 'registry',
            beforeEnter,
            component: () => import('@/modules/tsodd/views/tsodd-registry'),
            meta: {
              title: 'tsodd:title',
              subtitle: 'tsodd:registry_title',
              icon: 'road-sign',
              abbr: 'tsodd:abbr-title',
              description: 'tsodd:registry_desc'
            }
          }
        ]
      }
    ]
  }
}
