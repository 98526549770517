// const DEV_MODE = process.env.NODE_ENV === 'development'

import axios from 'axios'
import $store from 'STORE'
import { getCookie } from 'HELPERS'
import $router from '@/router'
import { ABS_URL, URL } from '@/config/constants'
import eventBus, { eventNames } from 'EVENT_BUS'
import i18n from '@/extensions/i18n'
// import { setupCache } from 'axios-cache-adapter'

axios.defaults.baseURL = URL

const { LICENSE_ERROR } = eventNames

export const setAxiosToken = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${
    token || getCookie('ritmToken')
  }`
}

setAxiosToken()

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

// Uncomment below when backend gets sick
// 1. yarn add axios-cache-adapter
// 2. repalce axios. → cachedAxios. in GET request below

// —————————————— HERE ——————————————
// const cache = setupCache({
//   debug: true,
//   maxAge: 15 * 60 * 1000, // == 15 min
//   exclude: { query: false }
// })

// const cachedAxios = axios.create({
//   adapter: cache.adapter
// })
// —————————————— HERE ——————————————

// Before git push:
// 1. yarn remove axios-cache-adapter
// 2. replace cachedAxios. → axios. in GET request below
// 3. Comment all above stuff

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

const errorHandler = (e, url) => {
  const status = e.response?.status
  const code =
    e.code === 'ERR_NETWORK' ||
    e.code === 'ERR_BAD_RESPONSE' ||
    e.code === 'ERR_BAD_REQUEST'
      ? 'Ошибка запроса'
      : e.code

  const msg = e.response?.data?.msg

  if (msg === 'User deleted') {
    $store.commit('CLEAR_USER_DATA')
    $router.push({ path: '/login' })

    return
  }

  switch (status) {
    case 400: {
      let message = i18n.t(msg === 'No already in use' ? 'no_is_in_use' : msg)

      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'danger',
          title: `Произошла ошибка`,
          message
        }
      ])

      break
    }

    case 500:
      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'danger',
          title: `${i18n.t('error:code')} ${status}`,
          message: `Подсистема недоступна. Повторите попытку позже.${
            msg ? ` | ${i18n.t(msg)}` : ''
          }`
        }
      ])
      break
    case 401:
    case 422:
      $store.commit('CLEAR_USER_DATA')
      $router.push({ path: '/login' })
      break
    case 403:
      eventBus.emit(LICENSE_ERROR)

      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'warning',
          title: `У Вас нет доступа к этим данным`,
          message: 'Обратитесь к администратору'
        }
      ])
      $router.push({ path: '/explorer' })
      break
    default:
      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'danger',
          title: `${
            Number.isInteger(status)
              ? `${i18n.t('error:code')} ${status} | `
              : ''
          }${code}`,
          message: 'Обратитесь к администратору'
        }
      ])
      break
  }
}

export function GET(url, params, extra) {
  if (url.includes('+')) {
    const warn = `Use GET parameters as object(second arg). Broken url: ${url}`
    console.warn(warn)
  }

  const signal = extra?.signal
  const maxAge = extra?.maxAge

  const headers = {}

  if (Number.isInteger(maxAge)) {
    headers['Cache-Control'] = maxAge ? `max-age=${maxAge}` : 'no-cache'
  }

  return new Promise((resolve, reject) => {
    $store.commit('SYSTEM_PUSH', ['activeRequests', url])

    // —————————————— HERE ——————————————
    axios
      .get(url.replaceAll('+', '%2B'), {
        params,
        signal,
        headers
      })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          resolve({ data: [], canceled: true })
        } else {
          errorHandler(error)
          reject(error)
        }
      })
      .finally(() => {
        $store.commit('SYSTEM_SPLICE', ['activeRequests', url])
      })
  })
}

export function GET_BLOB(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { responseType: 'blob', params })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
  })
}

export function PUT(url, data, params = {}) {
  $store.commit('SYSTEM_PUSH', ['activeRequests', url])

  return new Promise((resolve, reject) => {
    axios
      .put(url, data, { params })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
      .finally(() => {
        $store.commit('SYSTEM_SPLICE', ['activeRequests', url])
      })
  })
}

export function POST(url, data = {}, params = {}) {
  $store.commit('SYSTEM_PUSH', ['activeRequests', url])

  data ||= {}

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { params })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
      .finally(() => {
        $store.commit('SYSTEM_SPLICE', ['activeRequests', url])
      })
  })
}

export function POST_UPLOAD(url, data, name) {
  const tempId = (+new Date()).toString(16)

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded / e.total) * 100))

          $store.commit('SYSTEM_ASSIGN', {
            field: 'downloads',
            key: tempId,
            value: progress >= 99 ? null : { name, progress }
          })
        }
      })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
  })
}

export function POST_BLOB(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { responseType: 'blob' })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
  })
}

export function DELETE(url, body = null) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data: body })
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        errorHandler(error)
        reject(error)
      })
  })
}

export function CONFIG_PUT(url, config) {
  const configWithTimestamp = {
    ...config,
    system: {
      timestamp: new Date().getTime()
    }
  }

  return PUT(url, configWithTimestamp)

  // return new Promise((resolve, reject) => {
  //   console.log(configWithTimestamp)
  //   fetch(`${ABS_URL}/${url}`, {
  //     headers: {
  //       Authorization: `Bearer ${getCookie('ritmToken')}`,
  //       'Content-Type': 'text/plain',
  //       'Access-Control-Allow-Origin': '*'
  //     },
  //     method: 'PUT',
  //     body: JSON.stringify(configWithTimestamp),
  //     keepalive: true
  //   }).then(
  //     res => {
  //       console.log('res', res)
  //
  //       if (res.status === 200) {
  //         resolve(res)
  //       } else {
  //         reject(new Error('error'))
  //       }
  //     },
  //     error => {
  //       errorHandler(error)
  //       reject(new Error(error))
  //     }
  //   )
  // })
}

export default {
  setAxiosToken,
  POST_UPLOAD,
  CONFIG_PUT,
  POST_BLOB,
  GET_BLOB,
  DELETE,
  POST,
  GET,
  PUT
}
